<template>
  <div>跳转中......</div>
</template>
<script>
import { SecretLogin } from "@/api/teacherStudio";
export default {
  data() {
    return {};
  },
  created() {
    const dataObj = this.$route.query;
    let unserInfoData = {
      id: dataObj.id,
      username: dataObj.username,
      photo_url: dataObj.photo_url,
      aes_code: dataObj.aes_code,
      token: dataObj.token,
    };
    localStorage.setItem("unserInfoData", JSON.stringify(unserInfoData));
    if (this.$route.query.idcard) {
      SecretLogin({ key: this.$route.query.idcard }).then((res) => {
        const dataObjs = res.data;
        localStorage.setItem("unserInfoData", JSON.stringify(dataObjs));
        //把瓣学网登录的权限存在缓存里
        localStorage.setItem("aes_code", dataObj.aes_code);
        //将用户所有信息存进缓存
        localStorage.setItem("user_name", dataObj.username);
        //把token存在缓存里
        localStorage.setItem("token", dataObj.token);
        //把用户id存进缓存里
        localStorage.setItem("id", dataObj.id);
        //把登录显示放进缓存里
        localStorage.setItem("isShow", 1);
        //把用户头像放进缓存
        localStorage.setItem("photo_url", dataObj.photo_url);
        this.$nextTick(() => {
          if (this.$route.query.module) {
            if (this.$route.query.module.indexOf("/#/") == -1) {
              this.$router.push({
                path: "/" + this.$route.query.module,
              });
            } else {
              let gourl = this.$route.query.module;
              let gopath = gourl.slice(2);
              this.$router.push({
                path: gopath,
              });
            }
          } else {
            this.$router.push({
              path: "/index",
            });
          }
          // location.reload();
        });
      });
    } else if (this.$route.query.token) {
      //把瓣学网登录的权限存在缓存里
      localStorage.setItem("aes_code", dataObj.aes_code);
      //将用户所有信息存进缓存
      localStorage.setItem("user_name", dataObj.username);
      //把token存在缓存里
      localStorage.setItem("token", dataObj.token);
      //把用户id存进缓存里
      localStorage.setItem("id", dataObj.id);
      //把登录显示放进缓存里
      localStorage.setItem("isShow", 1);
      //把用户头像放进缓存
      localStorage.setItem("photo_url", dataObj.photo_url);
      this.$nextTick(() => {
        if (this.$route.query.url) {
          if (this.$route.query.url.indexOf("/#/") == -1) {
            this.$router.push({
              path: this.$route.query.url,
            });
          } else {
            let gourl = this.$route.query.url;
            let gopath = gourl.slice(2);
            this.$router.push({
              path: gopath,
            });
          }
        } else {
          this.$router.push({
            path: "/index",
          });
        }
        setTimeout(() => {
          // location.reload();
        }, 500);
      });
    } else {
      setTimeout(() => {
        this.$router.push({
          path: "/index",
        });
      }, 1000);
    }
  },
  methods: {},
};
</script>
